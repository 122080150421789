@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400&display=swap");
@import url("https://cdn.quilljs.com/1.3.6/quill.snow.css");
@import url("https://cdn.datatables.net/1.10.20/css/dataTables.bootstrap4.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/tempusdominus-bootstrap-4/5.0.1/css/tempusdominus-bootstrap-4.min.css");
@import "fontawesome-5.15.1/scss/fontawesome.scss";
@import "fontawesome-5.15.1/scss/solid.scss";
@import "fontawesome-5.15.1/scss/regular.scss";
@import "fontawesome-5.15.1/scss/light.scss";
@import "search.scss";

@media (max-width: 1198px) {
    .container {
        width: 100%;
        max-width: none;
    }
}
#editor {
    border: 1px solid #ddd;
    font-size: 14px;
    height: 200px;
}
.alert-top {
    position: fixed;
    z-index: 10000;
}
.dropdown-toggle::after {
    display:none
}
.progress {
    height: 10px;
}
.footer {
    background-color: #f5f5f5;
}
.footer > .container {
    padding-right: 15px;
    padding-left: 15px;
}
.col-form-label {
    text-align: right;
}
table.dataTable {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}
table.table-outlined {
    border: 1px solid #dee2e6;
    border-top: 0;
}
.page-header {
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 1.5em;
}
[data-toggle="collapse"].collapsed .if-not-collapsed {
    display: none;
}
[data-toggle="collapse"]:not(.collapsed) .if-collapsed {
    display: none;
}

/* Collections */
.collection-wrapper > div {
    border: 1px solid #dee2e6;
    margin-bottom: .5rem;
    padding: 1rem 1rem 0.5rem;
}

/* Logos */
.third-party-logo {
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 5px;
    margin-bottom: 5px;
}
.ndr-logo {
    background-image: url("/img/ndr_logo.png");
}
.brain-logo {
    background-image: url("/img/brain_logo.png");
}
.eyeota-logo {
    background-image: url("/img/eyeota_logo.png");
}
.semasio-logo {
    background-image: url("/img/semasio_logo.svg");
}

#chartjs-tooltip {
    background: #fff;
    border: 1px solid rgba(0,0,0,.125);
}

#chartjs-tooltip td {
    display: flex;
    align-items: center;
}

#chartjs-tooltip td span.color {
    width: 1em;
    height: 1em;
    border-width: 2px;
    border-style: solid;
    /* The background and border colors are set in js */
    display: inline-block;
    margin-right: 0.5em;
}

.bg-dark-hover {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.bg-dark-hover:hover {
    background-color: #e9ecef;
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.no-effect {
    pointer-events: none;
}