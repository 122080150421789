#search-wrapper {
    position: relative;
    background-color: #515b66;
    border: 0;
    border-radius: .5em;
}

#search-wrapper:focus {
    box-shadow: 0 0 0 .2rem rgba(233,12,138,.25);
}

#search {
    background-color: transparent;
    border: 0;
    color: white;
    display: none;
    width: 15em;
}

#search:focus {
    box-shadow: none;
}

#search::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

#search-results {
    position: absolute;
    top: 2.4rem;
    right: -1rem;
    max-height: 50vh;
    width: 80vw;
    max-width: 50rem;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}

#search-results > .list-group-item {
    scroll-snap-align: start;
}

#search-results > .skeleton > span {
    background-color: rgba(0, 0, 0, 0.05);
    animation-name: search-skeleton;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    border-radius: 0.2rem;
}

@keyframes search-skeleton {
    0% {
        background-color: rgba(0, 0, 0, 0.05);
    }

    50% {
        background-color: rgba(0, 0, 0, 0.1);
    }

    100% {
        background-color: rgba(0, 0, 0, 0.05);
    }
}

#search-results > span.info {
    padding: 2rem;
}

#search-results .type {
    margin: auto 1rem auto auto;
    flex-basis: 5rem;
    text-align: center;
    border-radius: 0.2rem;
    color: rgba(0, 0, 0, 0.8);
}

#search-results .name {
    margin: auto 0 auto 1rem;
    flex-basis: 20rem;
    word-break: break-word;
}

#search-results .info {
    margin: auto 0 auto 1rem;
    flex-basis: 20rem;
}

#search-results .date {
    margin: auto 0 auto 1rem;
    flex-basis: 5rem;
    text-align: right;
}

@media (max-width: 1200px) {
    #search-wrapper {
        position: static;
    }

    #search-results {
        position: absolute;
        top: 3.6rem;
        margin: 0 auto 0 auto;
        max-height: 50vh;
        width: 95vw;
    }
}

@media (max-width: 768px) {
    #search-wrapper {
        display: none;
    }
}